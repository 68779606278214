:root {
    --primary: var(--green) !important;
    --secondary: var(--fadeGreen) !important;
    --green: #22C687 !important;
    --fadeGreen: #4ED19F;
    --bluwishGray: #4e5983;
    --warning: #FF4343 !important;
    --gray: #707070 !important;
    --white: #fff !important;
    --black: #000000 !important;
}

html, body, #root {
    height: 100%;
}

*, :focus, *:hover {
    outline: none;
}

* {
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari, Edge */
    color-adjust: exact !important; /*Firefox*/
}

.App {
    font-family: 'Poppins', sans-serif;
    height: 100%;
}

.nav-logo {
    font: normal normal 600 16px/16px Poppins;
    letter-spacing: 0px;
    color: #5895E9;
    padding-top: 0px !important;
    cursor: pointer;

}
.sa-table-data{
    font-size: 13px;
}
.form-control {
    color: #3C3C3C !important;
}
.list-view {
    padding-top: 96px;
    bottom: 0px;
    width: 100%;
    transition: 0.4s;
}

.h-100pe {
    height: 100%;
}

.full-list-view {
    height: 100%;
    padding-top: 133px;
}

.bg-gray {
    background-color: #F8F8F8;
}

.service-card {
    width: 32%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 8px #0000000A;
    border: 0.47999998927116394px solid #EDEDED;
    border-radius: 8px 8px 0px 0px;
    opacity: 1;
    position: relative;

}

.p-20 {
    padding: 20px;
}
/* 
.w-100 {
    padding: 20px;
} */

.service-card-icon {
    width: 40px;
    height: 40px;
    background: #EDF9F4 0% 0% no-repeat padding-box;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.txt-green {
    color: #00AA68;
}

.service-card-heading {
    font: normal normal 600 14px/21px Poppins;
    letter-spacing: 0px;
    color: #181D33;
}

.service-card-txt {
    font: normal normal normal 12px/18px Poppins;
    letter-spacing: 0px;
    color: #181D33;
    opacity: 0.48;
}
.add-icon{
    margin-right: 1px;
}
.service-card-sub-container {
    background: #EDEDED;
    opacity: 0.6;
    width: 100%;
    padding: 0 8px 0 20px;
    display: flex;
    align-items: center;

}

.service-card-sub-container-txt {
    font-size: 13px;
    font-weight: normal;
    letter-spacing: 0px;
    color: #1D283D;
    text-transform: capitalize;
}

.service-card-toggle-icon {
    width: 20px;
    height: 20px;
    color: #4A555F;
    cursor: pointer;
}

.service-card-more {
    position: absolute;
    right: 5px;
    rotate: 90deg;
    top: 10px;

}

.heading-txt {
    font: normal normal 600 16px/16px Poppins;
    letter-spacing: 0px;
    color: #111111;
    margin-bottom: 24px;
}

table tbody tr:nth-child(odd) {
    background-color: #f9f9f9;
}

.gap-20 {
    gap: 20px;
}

.service-card #serviceDropdown {
    position: absolute;
    right: 0;
}

.module-checkbox {
    height: 20px;
    width: 20px;
}

.module-checkbox-txt {
    font: normal normal normal 14px/21px Poppins;
    letter-spacing: 0px;
    color: #000000;
}

.module-checkbox-sub-txt {
    font: normal normal normal 12px/18px Poppins;
    letter-spacing: 0px;
    color: #707070;
}

.bg-primary {
    background-color: #5895E9 !important;
}
.sa-popup-header {
    margin-bottom: 24px;
    padding-top: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
    box-shadow: 0 4px 30px rgb(0 0 0 / 3%);
    background: #fff 0 0 no-repeat padding-box;
    opacity: 1;
    height: 60px;
}

.form-label {
    font: normal normal normal 16px/25px Poppins;
    letter-spacing: 0px;
    color: #707070;
}

.view-more {
    font: normal normal normal 12px/18px Poppins;
    text-decoration: underline;
    text-align: right;
    margin-top: 12px;
}

.sa-bread-crumb {
    display: inline-block;
    z-index: 9;
    position: relative;
    color: #868DAA;
    font-size: 12px;
    border: 0.4px solid #EDEDED;
    border-radius: 4px;
    margin-left: -8px;
    pointer-events: all;
    box-shadow: 0px 0px 8px #0000000A;
}

.sa-bc-arrow {
    color: #ddd;
    margin-right: 4px;
    height: 19px;
}

.icon-in-btn {
    width: 16px !important;
    height: 16px !important;
    margin-top: -2px !important;
    margin-right: 2px;
}

.pis-add-activityFields .icon-in-btn {
    margin-left: -3px;
}

  .icon-in-btn {
    width: 16px !important;
    height: 16px !important;
    margin-top: -2px !important;
    margin-right: 2px;
  }

.sa-bc-plot {
    display: inline-block;
    background: #fff;
    height: 36px;
    padding: 9px 9px 9px 0;
    border-radius: 0 4px 4px 0;
}

.sa-bc-arrow {
    color: #ddd;
    margin-right: 4px;
    height: 19px;
}

.sa-bc-home {
    display: inline-block;
    background: #fff;
    height: 36px;
    padding: 9px 12px;
    border-radius: 4px 0 0 4px;
}

.sa-cursor {
    cursor: pointer;
}

.sa-bc-farm {
    display: inline-block;
    background: #fff;
    height: 36px;
    padding: 9px 9px 9px 0;
}

.map-toggle-btn {
    top: 64px;
    width: 100%;
    padding: 16px 0;
    background-color: transparent;
    transition: 0.4s;
    z-index: 9;
    pointer-events: none;
}

.second-tog {
    border-radius: 0px 4px 4px 0px !important;
    width: 105px;
}
#toggle-list, #toggle-map {
    border: none !important;
}
.tog-sa-secondary {
    background: #FFF 0% 0% no-repeat padding-box !important;
    border-radius: 0;
    border: 0.23999999463558197px solid #EDEDED;
    opacity: 1;
    height: 36px;
    color: #2A7CED !important;
    font-size: 14px;
}

.tog-sa-primary {
    background: #2A7CED 0% 0% no-repeat padding-box !important;
    border-radius: 0;
    border: 0.23999999463558197px solid #EDEDED;
    opacity: 1;
    height: 36px;
    color: #fff !important;
    font-size: 14px;
}
.first-tog {
    border-radius: 4px 0px 0px 4px !important;
    width: 105px;
}
.card-sub-contain{
    padding: 6px 20px;
}
.available-module-txt{
    font: normal  normal 14px/21px Poppins;
}
.table-action-btn{
    padding: 5px 22px;
    background-color: #EDEDED;
    color: #7A869A;
    border-radius: 12px;
    opacity: 0.54;
    cursor: not-allowed;
}
.table-action-btn-active{
    background-color: white;
    color: #2A7CED;
    cursor: pointer;
    border: 1px solid #2A7CED;
    /*font: normal normal 12px/20px Poppins;*/
    font-weight: bold;
    opacity: 1;
}
.w-50px{
    width: 50px !important;
}
.sa-table-item-align {
    align-items: center;
    width: 50px;
}
.rbt-input-multi {
    display: flex !important;
    align-items: center !important;
}
.active-border{
    border: 1px solid #00AA68 !important;
}
.deactive-border{
    border: 1px solid #F7644E !important;
}
.header-container{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 0.25px solid #BCB9B9;
    border-radius: 10px;
    padding: 24px 28px;
    margin-bottom: 24px;
}

.header-sub-txt{
    font: normal normal normal 14px/25px Poppins;
    letter-spacing: 0px;
    color: #707070;
}

.blue-txt{
    color: #007bff !important;
}
.btn-sa-danger{
    background: #ff4343 !important;
}
.grey-txt{
    color: #aaa !important;
}
.edit-header{
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
}

.sa-table-container{
    max-height: calc(100vh - 150px) !important;
}

.data-box {
    margin-bottom: 10px;
    border-radius: 10px;
}

.activityAddField {
    margin-top: 14px;
}

.labelSize {
    font-size: 16px;
    color: #707070;
    opacity: 1;
    vertical-align: top;
    margin-right: 4px;
}

.btn-sa-secondary {
    background: #FFF 0% 0% no-repeat padding-box !important;
    border-radius: 4px !important;
    border: solid 1px #22C687 !important;
    opacity: 1;
    height: 44px;
    color: #22C687 !important;
    font-size: 14px;
}



